import { UPDATE_USER_FEEDBACK_SCORE_SUCCESS } from '../../redux/constants'

// #region Errors Types
const errorTypes = {
  DUPLICATE_EMAIL: 'Ya hay un usuario registrado con este email',
  LOCAL_STORAGE_NOT_FOUND: 'Sesión cerrada, vuelva a iniciar sesión',
  LOCAL_STORAGE_NOT_SETTED:
    'Error de sesión, inténtalo de nuevo o contacte con el administrador',
  LOCAL_STORAGE_NOT_REMOVED:
    'Error de sesión, inténtalo de nuevo o contacte con el administrador',
  GENERIC_ERROR:
    'Algo ha salido mal, inténtalo de nuevo o contacte con el administrador',

  // Password Errors
  PASSWORD_POLICY:
    'La contraseña no cumple con la política de seguridad: Debe contener caracteres especiales, una mayúscula, una minúscula y un número',
  FORGOT_PASSWORD_NOT_FOUND: 'El correo no existe',
  FORGOT_PASSWORD_GOOGLE_ONLY: 'Este usuario debe iniciar sesión con Google',

  //  User Errors
  CREATE_USER_ERROR: 'No se ha podido crear el usuario, inténtalo de nuevo',
  CREATE_CLIENT_ERROR: 'No se ha podido crear el usuario, inténtalo de nuevo',
  UPDATE_USER_SUCCESS_IMAGE_NOT_VALID:
    'La imagen no es válida, por favor adjunta una nueva',
  UPDATE_USER_ERROR_CIF_USED: 'Ya hay un usuario registrado con este CIF',
  UPDATE_USER_ERROR: 'Usuario no actualizado, inténtelo de nuevo',
  UPDATE_USER_FEEDBACK_SCORE_SUCCESS: 'Error al actualizar la puntuación',

  //  SUBSTITUTE_EXCLUDED ERRORS
  ADD_SUBSTITUTE_EXCLUDED_ERROR:
    'No se ha podido excluir al sustituto, inténtalo de nuevo',
  REMOVE_SUBSTITUTE_EXCLUDED_ERROR:
    'No se ha podido eliminar al sustituto, inténtalo de nuevo',

  //  Partner Management Errors
  SET_LINKED_PARTNERS_ERROR:
    'No se han podido asociar los partners seleccionados',
  DELETE_LINKED_PARTNER_ERROR: 'No se han podido desvincular el partner',
  CHANGE_PARTNER_TO_COMPANY_ERROR:
    'Ha ocurrido algun error y no se ha realizado el cambio',
  // #endregion

  //  Custom Field Errors
  ADD_CUSTOM_FIELD_ERROR: 'Solo se permiten 5 campos personalizados activos',
  UPDATE_CUSTOM_FIELD_ERROR: 'Solo se permiten 5 campos personalizados activos',

  //  Project Long Term Management Errors
  CREATE_PROJECT_LONG_TERM_ERROR_404:
    'No tenemos colaboradores disponibles para este tipo de encargo. Contacta con el administrador.',
  CREATE_PROJECT_LONG_TERM_ERROR_GENERIC:
    'Ha ocurrido un error, si persiste contacte con el administrador.',
  LOAD_PROJECTS_LONG_TERM_ERROR:
    'Ha ocurrido un error, si persiste contacte con el administrador.',
  LOAD_ASSIGNED_PROJECTS_LONG_TERM_ERROR:
    'Ha ocurrido un error, si persiste contacte con el administrador.',
  LOAD_PROJECT_LONG_TERM_ERROR:
    'Ha ocurrido un error, si persiste contacte con el administrador.',
  UPDATE_PROJECT_LONG_TERM_ERROR:
    'Ha ocurrido un error, si persiste contacte con el administrador.',
  DELETE_PROJECT_LONG_TERM_ERROR:
    'Ha ocurrido un error, si persiste contacte con el administrador.',
  PROJECT_LONG_DOC_RECLAMATION_ERROR:
    'Ha ocurrido un error enviando el recordatorio.',
  REASSIGN_PROJECT_LONG_TERM_ERROR:
    'Ha ocurrido un error al reasignar el encargo.',
  APPLY_PROJECT_LONG_TERM_ERROR:
    'Ha ocurrido un error al aplicar para el proyecto.',
  ASSIGN_PROJECT_LONG_TERM_ERROR: 'Ha ocurrido un error al asignar el encargo.',
  CREATE_PROJECT_LONG_MEETING_ERROR: 'Error al crear la reunión.',
  DECLINE_PROJECT_LONG_TERM_CANDIDATE_ERROR: 'Error al rechazar el candidato.',

  // User Reminders Errors
  CREATE_USER_REMINDER_STATUS_ERROR: 'Error al crear recordatorio de usuario',
  FILE_DOWNLOAD_ERROR: 'Error al descargar el archivo',
  BLOB_PROCESSING_ERROR: 'Error al procesar el archivo descargado',
  DELETE_USER_ERROR: 'Error al borrar el usuario',

  // PigenAI Errors
  PIGEONAI_UPLOAD_FILES_ERROR: 'Error al subir los archivos',
  PIGEONAI_DOWNLOAD_FILE_ERROR: 'Error al descargar los archivos',
  PIGEONAI_GET_STATUS_ERROR: 'Error al obtener el estado de los archivos',
  PIGEONAI_INSTRUCTA_CANCELED: 'Hubo un error al generar la instructa',

  // Candidate Management Errors

  DELETE_PROJECT_CANDIDATE_ERROR: 'Error al borrar el candidato',
  UPDATE_PROJECT_CANDIDATE_ERROR: 'Error al actualizar el candidato',
}

// #region Success Types
const successTypes = {
  //  General Success
  AUTH_POOL_SUCCESS: 'Se ha realizado el cambio correctamente',
  GENERIC_SUCCESS: 'Operación realizada correctamente.',

  //  User Management Success
  USER_CREATED: 'Usuario creado',
  UPDATE_USER_SUCCESS: 'Usuario actualizado',
  DELETE_USER_SUCCESS: 'Usuario eliminado',
  CREATE_CLIENT_SUCCESS: 'Usuario registrado correctamente',
  UPDATE_USER_FEEDBACK_SCORE_SUCCESS: 'Puntuación actualizada correctamente',

  //  SUBSTITUTE_EXCLUDED ERRORS
  SUBSTITUTE_EXCLUDED: 'Sustituto excluido',
  SUBSTITUTE_REMOVED: 'Sustituto desbloqueado',
  PARTNER_LINKED: 'Partner vinculado correctamente',
  CHANGE_PARTNER_TO_COMPANY_SUCCESS:
    'Cambio de empresa realizado correctamente',

  //  Partner Management Success
  ADD_SUBSTITUTE_EXCLUDED_SUCCESS: 'Sustituto excluido',
  REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS: 'Sustituto desbloqueado',

  //  Favorite Substitute Success
  ADD_FAVORITE_SUBSTITUTE_SUCCESS: 'Sustituto añadido a favortios',
  REMOVE_FAVORITE_SUBSTITUTE_SUCCESS: 'Sustituto desmarcado como favorito',

  //  Project Long Term Management Success
  CREATE_PROJECT_LONG_TERM_SUCCESS: 'Un proyecto para usted ha sido creado.',
  UPDATE_PROJECT_LONG_TERM_SUCCESS: 'El proyecto ha sido actualizado.',
  DELETE_PROJECT_LONG_TERM_SUCCESS: 'El proyecto ha sido eliminado.',
  PROJECT_LONG_DOC_RECLAMATION_SUCCESS: 'Recordatorio enviado.',
  REASSIGN_PROJECT_LONG_TERM_SUCCESS: 'Encargo reasignado.',
  APPLY_PROJECT_LONG_TERM_SUCCESS: 'Has aplicado para el proyecto.',
  ASSIGN_PROJECT_LONG_TERM_SUCCESS: 'El proyecto ha sido asignado.',
  CREATE_PROJECT_LONG_MEETING_SUCCESS: 'La reunión ha sido creada.',
  DECLINE_PROJECT_LONG_TERM_CANDIDATE_SUCCESS:
    'Has rechazado el candidato correctamente.',

  //  User Reminders Success
  CREATE_USER_REMINDER_STATUS_SUCCESS: 'Notificación creada correctamente',
  CREATE_USER_SUCCESS: 'Usuario creado correctamente',

  // PigenAI Success
  PIGEONAI_DOWNLOAD_FILE_SUCCESS: 'Archivo descargado con éxito',
  PIGEONAI_UPLOAD_FILES_SUCCESS: 'Archivos subido correctamente',

  // Candidate Management Success
  UPDATE_PROJECT_CANDIDATE_SUCCESS: 'Candidato actualizado correctamente',
  DELETE_PROJECT_CANDIDATE_SUCCESS: 'Candidato eliminado correctamente',

  // Password Success
  FORGOT_PASSWORD_EMAIL_SENT:
    'Email enviado con las instrucciones para resetear la contraseña',
}

const toastTypes = { errorTypes, successTypes }

export default toastTypes
