const styles = {
  button: {
    width: '100%',
    height: '125px',
  },
  cardContainer: {
    position: 'relative',
    width: '100%',
  },
  cardBody: {
    width: '100%',
  },
  badge: (color) => ({
    position: 'absolute',
    right: '-26px',
    top: '0px',
    width: '90px',
    transform: 'rotate(45deg)',
    backgroundColor: 'white',
    border: `2px solid ${color}`,
    borderRadius: '10px',
    padding: '3px',
    color: color,
    fontWeight: 'bold',
  }),
  icon: {
    width: '50px',
  },
  title: (show) => ({
    color: show ? 'inherit' : 'transparent',
    textAlign: 'left',
    textShadow: !show ? '0 0 20px #000' : 'none',
  }),
  content: (show) => ({
    color: show ? 'inherit' : 'transparent',
    textShadow: !show ? '0 0 20px #000' : 'none',
    textAlign: 'left',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textDecoration: 'none',
  }),
}

export default styles
