import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ON_BOARD_CLIENTS } from '../../../config'
import logoImg from '../../../assets/images/2.png'
import { options } from './productsOptions'
import NewAssignmentOption from '../components/NewAssignmentOptions'
import styles from '../utils/createNewAssigmentUtils'

const CreateNewAssignment = ({ closeModal, setIsSecondLevel }) => {
  const navigate = useNavigate()

  const { activeUser } = useSelector((state) => state.auth)
  const [selectedOption, setSelectedOption] = useState(null)

  const handleOptionClick = (option) => {
    if (option.suboptions) {
      setSelectedOption(option)
      setIsSecondLevel(true)
    } else {
      closeModal()
      navigate(option.to)
    }
  }

  const handleOnClickBack = () => {
    setSelectedOption(null)
    setIsSecondLevel(false)
  }

  options.forEach((option) => {
    option.suboptions.forEach((suboption) => {
      if (suboption.title === 'Pigeon On Board') {
        if (
          ON_BOARD_CLIENTS?.split(',').includes(String(activeUser._id)) &&
          activeUser.role !== 'admin'
        ) {
          suboption.show = true
          suboption.disabled = false
          suboption.isNew = true
          suboption.isCommingSoon = false
        } else {
          suboption.show = true
          suboption.disabled = true
          suboption.isNew = false
          suboption.isCommingSoon = true
        }
      }
    })
  })

  return (
    <>
      <div className="d-flex align-items-center bg-white">
        <div className="d-flex flex-column ms-5" style={{ width: '50%' }}>
          {selectedOption ? (
            <>
              <button
                className="btn btn-secondary mb-3"
                onClick={handleOnClickBack}
                style={{ position: 'absolute', top: '16px' }}
              >
                Atrás
              </button>
              {selectedOption.suboptions.map((suboption) => (
                <div
                  key={suboption.title}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    if (!suboption.disabled) {
                      closeModal()
                      navigate(suboption.to)
                    }
                  }}
                  onKeyDown={(e) => {
                    if (
                      !suboption.disabled &&
                      (e.key === 'Enter' || e.key === ' ')
                    ) {
                      closeModal()
                      navigate(suboption.to)
                    }
                  }}
                  className={`card btn btn-pill btn-outline-primary border border-primary rounded d-flex justify-content-center align-items-center ${
                    suboption.disabled ? 'disabled' : ''
                  }`}
                  style={{
                    ...styles.button,
                    cursor: suboption.disabled ? 'not-allowed' : 'pointer',
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={styles.cardContainer}
                  >
                    {suboption.isNew && (
                      <h4 style={styles.badge('green')}>New!!</h4>
                    )}
                    {suboption.isCommingSoon && (
                      <h4 style={styles.badge('black')}>Coming soon!</h4>
                    )}
                    {suboption.fontAwesome && (
                      <FontAwesomeIcon
                        className="ml-3"
                        size="2x"
                        icon={suboption.fontAwesome}
                        style={styles.icon}
                      />
                    )}
                    <div className="card-body" style={styles.cardBody}>
                      <h3 style={styles.title(suboption.show)}>
                        <strong>{suboption.title}</strong>
                      </h3>
                      <p
                        dangerouslySetInnerHTML={{ __html: suboption.content }}
                        className="mb-0"
                        style={styles.content(suboption.show)}
                      ></p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            options.map((option) => (
              <NewAssignmentOption
                option={option}
                handleOptionClick={handleOptionClick}
                key={option.title}
              />
            ))
          )}
        </div>

        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: '50%' }}
        >
          {!selectedOption && (
            <img
              src={logoImg}
              alt="Logo"
              className="img-fluid"
              style={{
                objectFit: 'contain',
                maxHeight: '660px',
                width: '100%',
              }}
            />
          )}

          {selectedOption && selectedOption.imgSrc && (
            <img
              src={selectedOption.imgSrc}
              alt={selectedOption.title}
              className="img-fluid"
              style={{
                objectFit: 'contain',
                maxHeight: '414px',
                width: '100%',
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default CreateNewAssignment
