import * as types from '../constants'
import { toast } from 'react-toastify'

const initialState = {
  userReminders: [],
  adminReminders: [],
  loading: false,
  error: null,
  created: {},
}

export const userReminder = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_USER_REMINDERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case types.LOAD_USER_REMINDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        userReminders: [...action.payload.userReminders],
      }

    case types.LOAD_USER_REMINDERS_ERROR:
      toast.error('Error al cargar recordatorios de usuario', {
        theme: 'colored',
      })
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case types.UPDATE_USER_REMINDERS_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.UPDATE_USER_REMINDERS_STATUS_SUCCESS: {
      return {
        ...state,
        userReminders: action.payload.userReminders,
        loading: false,
      }
    }

    case types.UPDATE_USER_REMINDERS_STATUS_ERROR:
      toast.error('Error al actualizar recordatorio de usuario', {
        theme: 'colored',
      })
      return state

    case types.CREATE_USER_REMINDER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.CREATE_USER_REMINDER_STATUS_SUCCESS:
      return {
        ...state,
        userReminders: [
          ...state.userReminders,
          ...action.payload.userReminders,
        ],
        adminReminders: [
          ...state.adminReminders,
          ...action.payload.adminReminders,
        ],
        created: action.payload.userReminder,
        loading: false,
      }

    case types.CREATE_USER_REMINDER_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
