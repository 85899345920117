import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../utils/createNewAssigmentUtils'

const NewAssignmentOption = ({ option, handleOptionClick }) => {
  return (
    <div key={option.title}>
      <button
        className={`card btn btn-pill btn-outline-primary border border-primary rounded d-flex justify-content-center align-items-center ${
          !option.show || option.disabled ? 'disabled' : ''
        }`}
        onClick={() => {
          if (!option.disabled) handleOptionClick(option)
        }}
        style={styles.button}
        disabled={option.disabled}
      >
        <div className="d-flex align-items-center" style={styles.cardContainer}>
          {option.isNew && <h4 style={styles.badge('green')}>New!!</h4>}
          {option.isCommingSoon && (
            <h4 style={styles.badge('black')}>Coming soon!</h4>
          )}
          {option.fontAwesome && (
            <FontAwesomeIcon
              className="ml-3"
              size="2x"
              icon={option.fontAwesome}
              style={styles.icon}
            />
          )}
          <div className="card-body" style={styles.cardBody}>
            <h3 style={styles.title(option.show)}>
              <strong>{option.title}</strong>
            </h3>
            <p
              dangerouslySetInnerHTML={{ __html: option.content }}
              className="mb-0"
              style={styles.content(option.show)}
            ></p>
          </div>
        </div>
      </button>
    </div>
  )
}

export default NewAssignmentOption
