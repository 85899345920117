import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu, ListGroup } from 'reactstrap'

const NavbarDropDown = ({
  icon,
  newNotificationsCount,
  isOpen,
  toggle,
  onDropdownClose,
  href,
  children,
}) => {
  const activeUser = useSelector((state) => state.auth.activeUser)

  useEffect(() => {
    if (!isOpen && onDropdownClose) {
      onDropdownClose()
    }
  }, [isOpen, onDropdownClose])

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      className="me-2 nav-item"
      direction="down"
    >
      <DropdownToggle tag="a" className="nav-link nav-icon dropdown-toggle p-2">
        {icon}
      </DropdownToggle>

      <DropdownMenu
        style={{
          backgroundColor: 'white',
        }}
        end
        className="dropdown-menu-lg py-0 custom-dropdown-style selected-blue border-0"
      >
        {newNotificationsCount > 0 ? (
          <div
            className="dropdown-menu-header p-2 border-bottom"
            style={{ backgroundColor: '#f5f9fc' }}
          >
            <div className="d-flex flex-column align-items-start">
              <div className="text-center mt-1 w-100">
                <span className="fw-bold text-black">
                  {newNotificationsCount > 1 ? (
                    <>
                      <span className="text-danger">
                        {newNotificationsCount}
                      </span>{' '}
                      Nuevas notificaciones
                    </>
                  ) : (
                    <>
                      <span className="text-danger">
                        {newNotificationsCount}
                      </span>{' '}
                      Nueva notificación
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="dropdown-menu-header p-2 border-bottom"
            style={{ backgroundColor: '#f5f9fc' }}
          >
            <div className="text-center mt-1 w-100">
              <span className="fw-bold text-muted">
                No hay nuevas notificaciones
              </span>
            </div>
          </div>
        )}

        <ListGroup>{children}</ListGroup>

        {!activeUser.is_substitute && !activeUser.is_procura && (
          <div
            className="dropdown-menu-footer p-2"
            style={{ backgroundColor: '#f5f9fc' }}
          >
            <a
              href={
                activeUser.role === 'client'
                  ? `${href}#notificaciones`
                  : activeUser.role === 'admin'
                  ? '/panel/user-reminders'
                  : ''
              }
              className="text-muted"
            >
              Ir a todas las notificaciones
            </a>
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

export default NavbarDropDown
