import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  remittances: {},
}

export const remittance = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_REMITTANCES_SUCCESS:
      return {
        ...state,
        remittances: action.payload,
      }

    case types.GET_REMITTANCES_ERROR:
      return {
        ...state,
      }

    case types.SET_REMITTANCE_PROCESSED_SUCCESS:
      const index = state.remittances.docs.findIndex(
        (rem) => rem._id === action.payload.remittance._id
      )
      const newDocs = state.remittances.docs
      newDocs[index] = action.payload.remittance

      return {
        ...state,
        remittances: {
          ...state.remittances,
          docs: newDocs,
        },
      }

    case types.REPEAT_INVOICE_REMITTANCE_SUCCESS:
      return {
        ...state,
      }

    case types.ROLLBACK_REMITTANCE_SUCCESS:
      return {
        ...state,
      }
    case types.ROLLBACK_INVOICE_REMITTANCE_SUCCESS:
      return {
        ...state,
      }
    case types.IMPORT_HOLDED_REMITTANCE_SUCCESS:
      return {
        ...state,
      }

    case types.SET_REMITTANCE_PROCESSED_ERROR:
    case types.REPEAT_INVOICE_REMITTANCE_ERROR:
    case types.ROLLBACK_REMITTANCE_ERROR:
    case types.ROLLBACK_INVOICE_REMITTANCE_ERROR:
    case types.IMPORT_HOLDED_REMITTANCE_ERROR:
      toast.error(action.payload.response.message, { theme: 'colored' })
      return {
        ...state,
      }

    default:
      return state
  }
}
