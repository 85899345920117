import { handleError } from '../../helpers/toastHelpers/toastHandlers'
import toastTypes from '../../helpers/toastHelpers/toastTypes'
import {
  removeItemFromLocalStorage,
  setItemToLocalStorage,
} from '../../utils/localStorageUtils/fromLocalStorage'
import * as types from '../constants'

const { errorTypes } = toastTypes

const localStorageMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case types.CREATE_CLIENT_SUCCESS:
      try {
        setItemToLocalStorage('token', action.payload.token)
      } catch (error) {
        handleError(errorTypes.LOCAL_STORAGE_NOT_SETTED)
      }
      break

    case types.CREATE_CLIENT_ERROR:
      try {
        removeItemFromLocalStorage('token')
      } catch (error) {
        handleError(errorTypes.LOCAL_STORAGE_NOT_REMOVED)
      }
      break

    default:
      break
  }

  return next(action)
}

export default localStorageMiddleware
