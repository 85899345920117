import * as types from '../constants'

const defaultState = {
  projectLongTerm: {},
  projectsLongTerm: [],
  created: {},
  assigned: {},
  loading: false,
}

export const projectLongTerm = (state = defaultState, action) => {
  switch (action.type) {
    case types.CREATE_PROJECT_LONG_TERM_REQUEST:
    case types.LOAD_PROJECTS_LONG_TERM_REQUEST:
    case types.LOAD_ASSIGNED_PROJECTS_LONG_TERM_REQUEST:
    case types.LOAD_PROJECT_LONG_TERM_REQUEST:
    case types.UPDATE_PROJECT_LONG_TERM_REQUEST:
    case types.DELETE_PROJECT_LONG_TERM_REQUEST:
    case types.PROJECT_LONG_DOC_RECLAMATION_REQUEST:
    case types.REASSIGN_PROJECT_LONG_TERM_REQUEST:
    case types.APPLY_PROJECT_LONG_TERM_REQUEST:
    case types.ASSIGN_PROJECT_LONG_TERM_REQUEST:
    case types.CREATE_PROJECT_LONG_MEETING_REQUEST:
    case types.DECLINE_PROJECT_LONG_TERM_CANDIDATE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.CREATE_PROJECT_LONG_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        created: action.payload.projectlt,
      }

    case types.LOAD_PROJECTS_LONG_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        projectsLongTerm: action.payload,
      }

    case types.LOAD_ASSIGNED_PROJECTS_LONG_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        assigned: action.payload,
      }

    case types.LOAD_PROJECT_LONG_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        projectLongTerm: action.payload.projectlt,
      }

    case types.UPDATE_PROJECT_LONG_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        projectLongTerm: action.payload.projectlt,
      }

    case types.DELETE_PROJECT_LONG_TERM_SUCCESS: {
      const { deleted } = action.payload

      return {
        ...state,
        loading: false,
        projectsLongTerm: {
          ...state.projectsLongTerm,
          docs: state.projectsLongTerm.docs.filter(
            (project) => project.id !== deleted
          ),
        },
      }
    }

    case types.PROJECT_LONG_DOC_RECLAMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        projectLongTerm: action.payload.projectst,
      }

    case types.REASSIGN_PROJECT_LONG_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        projectLongTerm: action.payload.projectlt,
      }
    case types.APPLY_PROJECT_LONG_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        projectLongTerm: action.payload.projectlt,
      }

    case types.ASSIGN_PROJECT_LONG_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        projectLongTerm: action.payload.projectlt,
      }

    case types.CREATE_PROJECT_LONG_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        projectLongTerm: {
          ...state.projectLongTerm,
        },
      }
    case types.DECLINE_PROJECT_LONG_TERM_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case types.CREATE_PROJECT_LONG_TERM_ERROR:
    case types.LOAD_PROJECTS_LONG_TERM_ERROR:
    case types.LOAD_ASSIGNED_PROJECTS_LONG_TERM_ERROR:
    case types.LOAD_PROJECT_LONG_TERM_ERROR:
    case types.UPDATE_PROJECT_LONG_TERM_ERROR:
    case types.DELETE_PROJECT_LONG_TERM_ERROR:
    case types.PROJECT_LONG_DOC_RECLAMATION_ERROR:
    case types.REASSIGN_PROJECT_LONG_TERM_ERROR:
    case types.APPLY_PROJECT_LONG_TERM_ERROR:
    case types.ASSIGN_PROJECT_LONG_TERM_ERROR:
    case types.CREATE_PROJECT_LONG_MEETING_ERROR:
    case types.DECLINE_PROJECT_LONG_TERM_CANDIDATE_ERROR:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
