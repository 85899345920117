import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toggleSidebar } from '../../redux/actions/settings'
import { logout } from '../../redux/actions/auth'
import CreateNewAssigmentModal from '../CreateNewAssigment/index'
import { googleLogout } from '../../services/socialLogin'
import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { Settings, User, LogOut } from 'react-feather'
import DefaultProfileImage from '../../assets/images/default_profile_image.png'
import StarsWithRating from '../rating/StarWithRating'
import { getProfileImage } from '../../../src/services/index'
import UserReminders from '../UserReminders/UserReminders'

const NavbarComponent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)
  const { activeUser } = auth
  const socialLogins = ['social', 'google']
  const [imageUrl, setImageUrl] = useState(null)

  const handleLogout = () => {
    if (socialLogins.includes(localStorage.getItem('loginType'))) {
      googleLogout()
    }
    dispatch(logout())
    navigate('/login')
  }

  useEffect(() => {
    if (activeUser && activeUser.profile_image) {
      fetchImage(activeUser.profile_image)
    }
  }, [activeUser])

  const fetchImage = async (image) => {
    const res = await getProfileImage(image)
    setImageUrl(res ? res.data.returnData.signedRequest : null)
  }

  if (!activeUser) {
    return null
  }

  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex me-2"
        onClick={() => dispatch(toggleSidebar())}
      >
        <i className="hamburger align-self-center" />
      </span>

      <div className="ms-auto d-flex align-items-center justifiy-content-center">
        <CreateNewAssigmentModal />

        {activeUser.is_substitute && (
          <div className="ms-4 py-1">
            <StarsWithRating substitute_id={activeUser._id} />
          </div>
        )}

        <Collapse className="ms-3" navbar>
          <Nav navbar>
            <UncontrolledDropdown nav inNavbar>
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle
                  nav
                  caret
                  className="link-no-underline d-flex flex-row align-items-center justify-content-center"
                >
                  {activeUser.is_substitute && (
                    <img
                      src={imageUrl || DefaultProfileImage}
                      className="me-2 avatar img-fluid rounded-circle my-0"
                      alt={`${activeUser.name} ${
                        activeUser.last_name ? activeUser.last_name[0] : ''
                      }`}
                    />
                  )}
                  <span className="text-dark">
                    {activeUser.name}{' '}
                    {activeUser.last_name ? activeUser.last_name[0] : ''}.
                  </span>
                </DropdownToggle>
              </span>
              <DropdownMenu end>
                <Link to={`/panel/users/${activeUser._id}`}>
                  <DropdownItem>
                    <User size={18} className="align-middle me-2" />
                    <span>Perfil</span>
                  </DropdownItem>
                </Link>
                <DropdownItem divider />
                <DropdownItem onClick={handleLogout}>
                  <LogOut size={18} className="align-middle me-2" />
                  <span>Salir</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
        <UserReminders />
      </div>
    </Navbar>
  )
}

export default NavbarComponent
