import React, { useEffect, useState } from 'react'
import AuthLayout from '../../layouts/Auth'
import PigeonImg from '../../components/common/PigeonImg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Form, FormGroup, Input } from 'reactstrap'
import { forgotPassword } from '../../helpers/sessionHelpers/forgotPassword'

const ForgotPassword = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [query, setQuery] = useState('')

  useEffect(() => {
    setQuery(new URLSearchParams(location.search.replace('?', '')))
  }, [location.search])

  const handleSubmit = (e) => {
    e.preventDefault()
    const email = e.target.email.value
    forgotPassword(email, navigate)
  }

  return (
    <AuthLayout>
      <div className="text-center">
        <Link to="/">
          <PigeonImg />
        </Link>
      </div>

      <Card>
        <CardBody className="text-center">
          {(!query || query.security !== 'PWDNotReset') && (
            <h1 className="h2 mt-4">Has olvidado tu contraseña?</h1>
          )}
          <p className="lead mb-3">
            Introduce tu email para resetear la contraseña.
          </p>
          <div className="m-sm-4">
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Email"
                />
              </FormGroup>
              <div className="text-center mt-4 mb-4">
                <Button color="primary" size="lg">
                  Resetear contraseña
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </AuthLayout>
  )
}

export default ForgotPassword
