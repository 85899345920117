import React, { useState, useEffect } from 'react'
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getSession } from '../redux/actions/auth'
import ability from '../ability'
import { GTM, MAINTENANCE } from '../config'

import Loading from '../components/common/Loading'
import LogIn from '../pages/auth/LogIn'
import LoginRoutes from '../routes/loginRoutes'
import ScrollToTop from '../components/common/ScrollToTop'
import ClientSignUpPage from '../pages/ClientSignUpPage'
import SubstituteSignUpPage from '../pages/SubstituteSignUpPage'
import ForgotPassword from '../pages/auth/ForgotPassword'
import ResetPassword from '../pages/auth/ResetPassword'
import Landing from '../pages/Landing'
import CreateClientExpedient from '../pages/CreateClientExpedient'
import CreateExpedient from '../pages/CreateExpedient'
import CreateExpedientForm from '../pages/createExpedientForm/CreateExpedientForm'
import CreateUserForm from '../pages/createUserForm/CreateUserForm'
import ThanksClient from '../pages/createUserForm/ThanksClient'
import ThanksSubstitute from '../pages/createUserForm/ThanksSubstitute'
import FreshdeskChat from '../components/common/FreshdeskChat'
import Maintenance from '../pages/Maintenance'
const RoutesComponent = () => {
  const [isAuth, setIsAuth] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (auth.activeUser._id) {
      setIsAuth(true)
      setLoading(false)
    } else if (token) {
      dispatch(getSession({ token }))
    } else {
      setLoading(false)
    }
  }, [auth, dispatch])

  useEffect(() => {
    const { activeUser } = auth
    if (activeUser._id) {
      if (GTM) {
        window.dataLayer.push({
          event: 'session',
          userId: activeUser._id,
          role: activeUser.role,
        })
      }

      ability.update(activeUser.rules)
      FreshdeskChat({ activeUser })

      if (location.pathname === '/login') {
        navigate('/')
      }
      setIsAuth(true)
      setLoading(false)
    } else {
      ability.update([])
      setIsAuth(false)
    }
  }, [auth, location, navigate])

  return (
    <ScrollToTop>
      <Routes>
        <Route path="/substitute-list" element={<Landing />} />
        <Route
          path="/create-client-expedient"
          element={<CreateClientExpedient />}
        />
        <Route path="/create-expedient" element={<CreateExpedient />} />
        <Route path="/client-signup" element={<ClientSignUpPage />} />
        <Route path="/substitute-signup" element={<SubstituteSignUpPage />} />
        <Route
          path="/forgot-password/:pwdReset?"
          element={<ForgotPassword />}
        />
        <Route
          path="/reset-password/:token/:pwdReset?"
          element={<ResetPassword />}
        />
        <Route path="/thanks-client" element={<ThanksClient />} />
        <Route path="/thanks-substitute" element={<ThanksSubstitute />} />
        <Route
          path="/create-expedient-form/:stepParam/"
          element={<CreateExpedientForm />}
        />
        <Route
          path="/create-user-form/:stepParam/"
          element={<CreateUserForm />}
        />

        <Route
          path="/create-user-form"
          element={
            <Navigate to={`/create-expedient-form/name${location.search}`} />
          }
        />
        <Route
          path="/create-expedient-form"
          element={
            <Navigate
              to={`/create-expedient-form/judicial-party${location.search}`}
            />
          }
        />

        {MAINTENANCE ? (
          <Route path="*" element={<Maintenance />} />
        ) : loading ? (
          <Route path="*" element={<Loading loading={true} />} />
        ) : isAuth ? (
          <Route path="*" element={<LoginRoutes />} />
        ) : (
          <Route path="*" element={<LogIn />} />
        )}
      </Routes>
    </ScrollToTop>
  )
}

export default RoutesComponent
