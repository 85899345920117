import { baseAPI } from '../../config'
import { handleError, handleSuccess } from '../toastHelpers/toastHandlers'
import toastTypes from '../toastHelpers/toastTypes'

export const forgotPassword = async (email, navigate) => {
  try {
    const url = `${baseAPI}/reset/${email}`
    const res = await fetch(url)

    if (res.status === 200) {
      handleSuccess(toastTypes.successTypes.FORGOT_PASSWORD_EMAIL_SENT)
      if (navigate) {
        navigate('/login?security=PWDResetDone')
      }
      return true
    }

    if (res.status === 404) {
      handleError(toastTypes.errorTypes.FORGOT_PASSWORD_NOT_FOUND)
      return false
    }

    if (res.status === 403) {
      handleError(toastTypes.errorTypes.FORGOT_PASSWORD_GOOGLE_ONLY)
      return false
    }

    handleError(toastTypes.errorTypes.GENERIC_ERROR)
    return false
  } catch (_err) {
    handleError(toastTypes.errorTypes.GENERIC_ERROR)
    return false
  }
}
